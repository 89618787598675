// theme.js

const theme = {
  colors: {
    header: '#EBEAE5',
    background: '#EBEAE5',
    footer: '#A5B6AC',
    button: '#A5B6AC',
    text: '#868686',
    textheader: '#868686',
    textfooter: '#FFFFFF'
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
    // add as many sizes as you need
  },
};

export default theme;