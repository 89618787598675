import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div style={{ marginBottom: '60px' }}>
        <Helmet>
          <title>Home - ho.li design</title>
          <meta name="description" content="Willkommen bei ho.li design, eurem ersten Anlaufpunkt für stilvolle und gemütliche Poufs – für drinnen und draußen." />
          <meta name="keywords" content="ho.li design, holi design, Poufs, Pouffe, Pouf, Sitzsack, Beanbag, Hocker, Outdoor, Garten, Sitzkissen, boho, quadratisch, rechteckig, boucle, bouclé" />
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Willkommen bei ho.li design</h1>
        <div className="home-container">
          <div className="home-content">
            <img src="/imgs/inUse2_square_small.jpg" alt="Home" className="home-image" />
            <div className="home-text">
              <p>Hallo liebe Besucher von ho.li design, <br />
                <br /> 
                <br /> 
              unsere ersten Poufs sind da! Das Sortiment wird in den nächsten Tage und Wochen stetig erweitert. <br /> 
              <br /> 
              Ihr seid auf der Suche nach einem gemütlichen Sitzkissen für euer Zuhause oder euren Garten? Dann seid ihr bei uns genau richtig! In verschiedenen Farben und Stoffen können unsere Poufs vielfältig als Sitzgelegenheit, Fußablage oder einfach als Deko-Element genutzt werden. <br />
              <br /> 
              Die Bezüge der Poufs werden aus hochwertigen und pflegeleichten Stoffen gefertigt. Die Füllung besteht aus EPS-Perlen, die sich optimal an den Körper anpassen und für ein angenehmes Sitzgefühl sorgen. Durch einen eingearbeiteten Waschsack können die Bezüge wenn nötig einfach gewaschen werden. <br />
              <br /> 
              Schaut euch gerne um und meldet euch bei uns wenn ihr Fragen habt.<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
