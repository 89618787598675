import React, { useState, useEffect, useContext } from 'react';
import { CartContext } from '../CartProvider';
import { useParams } from 'react-router-dom';

export function OrderCompleted() {
  const { paymentReference } = useParams();
  const [ordernumber, setOrdernumber] = useState(null);
  const { dispatch, setShippingAddress, setBillingAddress, setMailAddress } = useContext(CartContext);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    let retries = 0;

    const fetchStatus = () => {
      setTimeout(async () => {
        try {
          const response = await fetch(`/api/Checkout/CheckStatus?id=${paymentReference}`);
          const result = await response.json();

          if (result === 0 && retries < 3) {
            retries++;
            fetchStatus();
          } else {
            setStatus(parseInt(result));
            setLoading(false);

            if(result > 4) {
              dispatch({ type: 'CLEAR_CART', payload: {} });
              setShippingAddress(null);
              setBillingAddress(null);
              setMailAddress(null);
              setOrdernumber(result);
            }

            return;
          }
        } catch (error) {
          console.error('Error fetching status:', error);
          setLoading(false);
        }
      }, 3000);
    };

    fetchStatus();
  }, []);

  if (loading) {
    return <div>Deine Bestellung wird abgeschlossen. Bitte warten...</div>;
  }
  else if(status === -1 || status === 0) {
    return (
      <div>
        <h1 style={{ marginBottom: '40px' }}>Bestellung fehlgeschlagen</h1>
        <p>Leider ist mit deiner Bestellung etwas schief gegangen. Bitte versuche es erneut. Wenn das Problem weiterhin besteht, nimm bitte Kontakt zu uns auf.</p>
      </div>
    );
  }
  else if(status === 2 || status === 3 || status === 4) {
    return (
      <div>
        <h1 style={{ marginBottom: '40px' }}>Zahlung fehlgeschlagen</h1>
        <p>Leider ist mit deiner Bezahlung etwas schief gegangen. Bitte versuche es erneut mit einer anderen Bezahlmöglichkeit.</p>
      </div>
    );
  }
  else {
    return (
      <div>
        <h1 style={{ marginBottom: '40px' }}>Vielen Dank</h1>
        <p>Wir haben deine Bestellung mit der <strong>Bestellnummer {ordernumber}</strong> erhalten. Du erhältst in Kürze eine Bestätigungsmail und wir werden alles so schnell wie möglich versenden. Wir wünschen dir viel Freude an deiner Bestellung!</p>
      </div>
    );
  }
}
