import { Shop } from "./components/Shop";
import { About } from "./components/About";
import { Home } from "./components/Home";
import { Impressum } from "./components/Impressum";
import { Privacy } from "./components/Privacy";
import { Contact } from "./components/Contact";
import { Product } from "./components/Product";
import { Agb } from "./components/Agb";
import { ShoppingCart } from "./components/ShoppingCart";
import { OrderCompleted } from "./components/OrderCompleted";
import { CheckoutAddress } from "./components/CheckoutAddress";
import { CheckoutBillingAddress } from "./components/CheckoutBillingAddress";
import { CheckoutSummary } from "./components/CheckoutSummary";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/shop',
    element: <Shop />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/impressum',
    element: <Impressum />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/agb',
    element: <Agb />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/product/:identifier',
    element: <Product />
  },
  {
    path: '/cart',
    element: <ShoppingCart />
  },
  {
    path: '/checkoutAddress',
    element: <CheckoutAddress />
  },
  {
    path: '/checkoutBillingAddress',
    element: <CheckoutBillingAddress />
  },
  {
    path: '/checkoutSummary',
    element: <CheckoutSummary />
  },
  {
    path: '/ordercompleted/:paymentReference',
    element: <OrderCompleted />
  }
];

export default AppRoutes;
