import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../CartProvider';

export function CheckoutBillingAddress() {
  const navigate = useNavigate();
  const { billingAddress, setBillingAddress } = useContext(CartContext);

  const [firstName, setFirstName] = useState(billingAddress?.firstName || '');
  const [lastName, setLastName] = useState(billingAddress?.lastName || '');
  const [companyName, setCompanyName] = useState(billingAddress?.company || '');
  const [addressLine1, setAddressLine1] = useState(billingAddress?.addressLine1 || '');
  const [zip, setZip] = useState(billingAddress?.zip || '');
  const [city, setCity] = useState(billingAddress?.city || '');
  const [country, setCountry] = useState('Deutschland');

  const isFormValid = () => {
    const zipValid = /^\d{5}$/.test(zip);
    return firstName && lastName && addressLine1 && zipValid && city;
  };

  const handleContinue = () => {
    setBillingAddress({
      firstName: firstName,
      lastName: lastName,
      company: companyName,
      addressLine1: addressLine1,
      addressLine2: '',
      zip: zip,
      city: city,
      country: country
    });

    navigate('/checkoutSummary');
  }

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      <h1 style={{ marginBottom: '40px' }}>Rechnungsadresse</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '620px' }}>
        <div style={{ flex: '0 0 47%', marginRight: '3%' }}>
          <label htmlFor="inputbillingFirstname" style={{ display: 'block' }}>Vorname*</label>
          <input
            id="inputbillingFirstname"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{ width: '100%', maxWidth: '300px', boxSizing: 'border-box' }}
          />
        </div>
        <div style={{ flex: '0 0 47%', marginLeft: '3%' }}>
          <label htmlFor="inputbillingLastname" style={{ display: 'block' }}>Nachname*</label>
          <input
            id="inputbillingLastname"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{ width: '100%', maxWidth: '300px', boxSizing: 'border-box' }}
          />
        </div>
      </div>
      <div style={{ marginTop: '10px', width: '100%', maxWidth: '620px' }}>
        <label htmlFor="inputCompanyName" style={{ display: 'block' }}>Firmenname</label>
        <input
          id="inputCompanyName"
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          style={{ width: '100%', maxWidth: '620px', boxSizing: 'border-box' }}
        />
      </div>
      <div style={{ marginTop: '10px', width: '100%', maxWidth: '620px' }}>
        <label htmlFor="inputAddressLine1" style={{ display: 'block' }}>Strasse und Hausnummer*</label>
        <input
          id="inputAddressLine1"
          type="text"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
          style={{ width: '100%', maxWidth: '620px', boxSizing: 'border-box' }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '620px' }}>  
        <div style={{ marginTop: '10px', flex: '0 0 27%', marginRight: '3%' }}>
          <label htmlFor="inputZip" style={{ display: 'block' }}>Postleitzahl*</label>
          <input
            id="inputZip"
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            style={{ width: '100%', maxWidth: '200px', boxSizing: 'border-box' }}
          />
        </div>
        <div style={{ marginTop: '10px', flex: '0 0 67%', marginRight: '3%' }}>
          <label htmlFor="inputCity" style={{ display: 'block' }}>Ort*</label>
          <input
            id="inputCity"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            style={{ width: '100%', maxWidth: '400px', boxSizing: 'border-box' }}
          />
        </div>
      </div>
      <div style={{ marginTop: '10px', width: '100%', maxWidth: '620px' }}>
        <label htmlFor="inputCountry" style={{ display: 'block' }}>Land*</label>
        <input
          id="inputCountry"
          type="text"
          value={country}
          style={{ width: '100%', maxWidth: '620px', boxSizing: 'border-box' }}
          disabled
        />
      </div>
      <div style={{ marginTop: '15px', marginBottom: '30px'}}>* erforderliche Felder</div>
      <div style={{ marginBottom: '60px', display: 'flex', justifyContent: 'flex-end' }}>
        <button className="global-button" onClick={handleBack} style={{ margin: "10px", width: '200px' }}>Zurück</button>
        <button className="global-button" onClick={handleContinue} disabled={!isFormValid()} style={{ margin: "10px", width: '200px' }}>Weiter</button>
      </div>
    </div>
  );
}
