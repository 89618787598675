import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CartContext } from '../CartProvider';
import './Product.css';
import { Helmet } from 'react-helmet';

export function Product() {
  const [productDetail, setProductDetail] = useState(null);
  const { identifier } = useParams();
  const [itemCount, setItemCount] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const { showDialog, setShowDialog, dispatch, setLatestAddedItem } = useContext(CartContext);

  useEffect(() => {
    // Fetch fabrics
    fetch(`api/Product/${identifier}`)
      .then(response => response.json())
      .then(data => setProductDetail(data));
  }, [identifier]);

  const handleCountChange = (e) => {
    setItemCount(e.target.value);
  };

  const handleAddToCart = () => {
    dispatch({ type: 'ADD_ITEM', payload: {
        id: productDetail.identifier,
        quantity: itemCount
      }
    });
    setLatestAddedItem({
      product: productDetail,
      quantity: itemCount,
      totalPrice: parseFloat(productDetail.price.replace(',', '.').replace(/[^0-9.-]+/g,"")) * Number(itemCount)
    });
    setShowDialog(true);
  };

  const handleBackClick = () => {
    navigate('/shop', {
      state: location.state
    });
  };  

  return (
    <div>
      <Helmet>
          <title>Produkte - ho.li design</title>
          <meta name="description" content="Willkommen bei ho.li design, eurem ersten Anlaufpunkt für stilvolle und gemütliche Poufs – für drinnen und draußen." />
          <meta name="keywords" content="Poufs, Pouffe, Pouf, Sitzsack, Beanbag, Hocker, Outdoor, Garten, Sitzkissen, boho, quadratisch, rechteckig, boucle, bouclé" />
          <meta name="language" content="de" />
        </Helmet>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <img src="/imgs/back.png" alt="Back" onClick={handleBackClick} style={{ height: '20px', width: 'auto' }} />  
        <span onClick={handleBackClick} style={{ marginLeft: '10px', cursor: 'pointer' }}>Zurück zur Auswahl</span>
      </div>
      {productDetail && 
        <h1 style={{ marginBottom: '40px' }}>{productDetail.name}</h1>
      }
      <div className="product-container" style={{ display: 'flex', margin: '20px', marginBottom: '30px', zIndex: '1' }}>
        <div className="product-image" style={{ flex: 3 }}>
          {productDetail && productDetail.images && 
            <Carousel>
              {productDetail.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={productDetail.name} />
                </div>
              ))}
            </Carousel>
          }
        </div>
        <div className="product-details" style={{ flex: 4, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
          {productDetail && 
            <div className='product-details-text' style={{ marginRight: '50px' }}>
              <h1>{productDetail.price}</h1>
              <p><i>{productDetail.vatAndShipping}</i></p>
              <p>Artikelnummer: {productDetail.productNumber}</p>
              {productDetail.availability && productDetail.availabilityColor && 
                <div className='product-details-text' style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                  <span style={{ 
                    display: 'inline-block', 
                    height: '12px', 
                    width: '12px', 
                    borderRadius: '50%', 
                    backgroundColor: productDetail.availabilityColor,
                    marginRight: '10px' 
                  }}></span>
                  <p style={{ margin: 0 }}>{productDetail.availability}</p>
                </div>
              }
              <p>{productDetail.deliveryTime}</p>
              <div className='product-details-text' style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: '0', marginRight: '15px' }}>Menge:</p>
                <input 
                  type="number" 
                  min="1" 
                  value={itemCount} 
                  onChange={handleCountChange} 
                  style={{ width: '3em', textAlign: 'right' }}
                />
              </div>
              <button style={{ marginBottom: '20px'}} className="global-button" onClick={handleAddToCart} disabled={!productDetail.isInStock}>In den Warenkorb</button>
            </div>
          }   
        </div>
      </div>
      <p><strong>Beschreibung</strong></p>
      {productDetail && 
        <p style={{ marginBottom: '30px' }} dangerouslySetInnerHTML={{ __html: productDetail.description }}></p>
      }
      <p><strong>Technische Details</strong></p>
      {productDetail && 
        <p style={{ marginBottom: '30px' }} dangerouslySetInnerHTML={{ __html: productDetail.technicalDetails }}></p>
      }
      <p><strong>Hinweise zu Versand und Rückversand</strong></p>
      <p style={{ marginBottom: '30px' }}>Aufgrund der Abmessungen und des Gewichts der Poufs werden diese auch bei der Bestellungen mehrerer Poufs einzeln versendet. Auch wenn die Versandkosten lediglich einmal berechnet werden, solltet ihr für eine mögliche Rücksendung die auf eurer Seite anfallenden Rücksendekosten berücksichtigen. Ebenso können die Rücksendekosten pro Paket höher ausfallen als die von uns angegebenen Versandkosten. Das Gesamtgewicht inkl. Verpackung liegt bei etwa 6kg.</p>
    </div>
  );
}
