import React, { Component } from 'react';
import theme from '../theme';

export class Contact extends Component {
  static displayName = Contact.name;

  constructor(props) {
    super(props);
    this.state = {
      messageSent: false,
      error: false
    };
    this.errorMessage = '';
  }

  send = async () => {

    const FirstName = document.getElementById('inputFirstname').value;
    const LastName = document.getElementById('inputLastname').value;
    const Mail = document.getElementById('inputMail').value;
    const Message = document.getElementById('inputMessage').value;
    const Consent = document.getElementById('inputConsent').checked;

    this.setState({ error: false });

    if (!FirstName || !LastName || !Mail || !Message || !Consent) {
      this.errorMessage = 'Du musst alle Felder ausfüllen und der Datenschutzerklärung zustimmen.'
      this.setState({ error: true });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(Mail)) {
      this.errorMessage = 'Bitte gib eine gültige E-Mail Adresse ein.'
      this.setState({ error: true });
      return;
    }

    const htmlRegex = /<[^>]*>/;
    if (htmlRegex.test(FirstName) || htmlRegex.test(LastName) || htmlRegex.test(Mail) || htmlRegex.test(Message)) {
      this.errorMessage = 'Eingaben dürfen kein HTML oder andere potenziell schädliche Inhalte enthalten.'
      this.setState({ error: true });
      return;
    }

    const response = await fetch('api/Contact/Send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ FirstName, LastName, Mail, Message })
    });

    if (response.ok) {
      document.getElementById('inputFirstname').value = '';
      document.getElementById('inputLastname').value = '';
      document.getElementById('inputMail').value = '';
      document.getElementById('inputMessage').value = '';
      document.getElementById('inputConsent').checked = false;
      this.setState({ messageSent: true });
    } else {
      this.setState({ error: true });
      this.errorMessage = 'Die Nachricht konnte nicht gesendet werden. Bitte melde dich per E-Mail bei uns.';
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>
        <div style={{ flex: 1, minWidth: '300px', padding: '10px' }}>
          <h1 style={{ marginBottom: '40px' }}>Kontakt</h1>
          <p style={{ marginBottom: '30px' }}>
              Wenn Du Fragen zu uns, unseren Produkten oder deiner Bestellung hast, freuen wir uns von dir zu hören. Du erreichst uns entweder per E-Mail oder über das Formular. Wir werden uns bemühen, deine Anfrag so schnell wie möglich zu beantworten.
          </p>
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', height: '100%' }}>
          <img src="/imgs/mail.png" alt="Mail" height="20px" />
          <a href="mailto:info@holidesign.de" style={{ marginLeft: '10px', fontSize: 'inherit', textDecoration: 'none', color: 'inherit' }}>info@holidesign.de</a>
        </div>
        </div>
        <div style={{ flex: 1, minWidth: '300px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {this.state.messageSent && (
            <div style={{ marginBottom: '20px', color: 'green', textAlign: 'left', alignSelf: 'flex-start' }}>
              Deine Nachricht wurde erfolgreich gesendet!
            </div>
          )}
        {this.state.error && (
            <div style={{ marginBottom: '20px', color: 'orange', textAlign: 'left', alignSelf: 'flex-start' }}>
              {this.errorMessage}
            </div>
        )}
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '620px' }}>
            <div style={{ flex: '0 0 47%', marginRight: '3%' }}>
              <label htmlFor="inputFirstname" style={{ display: 'block' }}>Vorname</label>
              <input id="inputFirstname" type="text" style={{ width: '100%', maxWidth: '300px', boxSizing: 'border-box' }} />
            </div>
            <div style={{ flex: '0 0 47%', marginLeft: '3%' }}>
              <label htmlFor="inputLastname" style={{ display: 'block' }}>Nachname</label>
              <input id="inputLastname" type="text" style={{ width: '100%', maxWidth: '300px', boxSizing: 'border-box' }} />
            </div>
          </div>
          <div style={{ marginTop: '10px', width: '100%', maxWidth: '620px' }}>
            <label htmlFor="inputMail" style={{ display: 'block' }}>E-Mail Adresse</label>
            <input id="inputMail" type="email" style={{ width: '100%', maxWidth: '620px', boxSizing: 'border-box' }} />
          </div>
          <div style={{ marginTop: '10px', width: '100%', maxWidth: '620px' }}>
            <label htmlFor="inputMessag" style={{ display: 'block' }}>Deine Nachricht</label>
            <textarea id="inputMessage" style={{ width: '100%', minHeight: '150px', maxWidth: '620px', boxSizing: 'border-box' }} />
          </div>
          <div style={{ marginTop: '10px', display: 'flex', alignItems: 'flex-start' }}>
            <input id="inputConsent" type="checkbox" style={{ marginRight: '10px', marginTop: '5px' }} />
            <label htmlFor="inputConsent">
              Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Detaillierte Informationen findest du in der <a href="/privacy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.
            </label>
          </div>
          <div style={{ marginTop: '20px', marginBottom: '50px', width: '100%', maxWidth: '620px' }}>
            <button className="global-button" onClick={this.send} style={{ width: '100%', padding: '7px' }}>Senden</button>
          </div>
        </div>
      </div>
    );
  }
}
