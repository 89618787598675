import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../CartProvider';
import './ShoppingCart.css';

export function ShoppingCart() {
  const { cart, dispatch } = useContext(CartContext);
  const [productDetails, setProductDetails] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [deliveryCosts, setDeliveryCosts] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    loadProducts();
  }, [cart]);

  const handleQuantityChange = (id, quantity) => {
      dispatch({ type: 'UPDATE_ITEM', payload: {
        id: id,
        quantity: quantity
      }
    });
  };

  const handleRemoveFromCart = (id) => {
    dispatch({ type: 'REMOVE_ITEM', payload: {
        id: id
      }
    });
  };

  const handleCheckoutNew = () => {
    navigate('/checkoutAddress');
  }

  const loadProducts = () => {
    // Prepare the cart data
    const cartData = cart.map(item => ({ key: item.id, value: item.quantity }));

  // Fetch product details
  fetch('api/Product/GetProductDetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cartData),
  })
    .then(response => response.json())
    .then(data => 
      {
        setProductDetails(data.products);
        setTotalPrice(data.totalPrice);
        setDeliveryCosts(data.deliveryCost);
        setDeliveryTime(data.deliveryTime);
      });
  }

  return (
    <div>
      <h1 style={{ marginBottom: '40px' }}>Warenkorb</h1>
      <div className="product-details-container">
          {productDetails && productDetails.map((productDetail) => (
              <div key={productDetail.identifier} className="product-detail-item">
                  <img src={productDetail.image} alt={productDetail.name} className="product-imagesc" />
                  <p>{productDetail.name}</p>
                  <p className="product-unit-price">Einzelpreis: {productDetail.unitPrice}</p>
                  <p>Gesamt: {productDetail.totalPrice}</p>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                      <input 
                          style={{ width: '50px' }}
                          type="number" 
                          min="1" 
                          value={productDetail.quantity} 
                          onChange={(e) => handleQuantityChange(productDetail.identifier, e.target.value)}
                      />
                      <img 
                          width='20px'
                          height='auto'
                          style={{ marginLeft: '20px', cursor: 'pointer'  }}
                          src="/imgs/recycle-bin.png" 
                          alt="delete" 
                          onClick={() => handleRemoveFromCart(productDetail.identifier)}
                          cursor="pointer"
                      />
                  </div>
              </div>
          ))}
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p style={{ fontStyle: 'italic', textAlign: 'right'}}>{deliveryCosts}</p>
        <p style={{ fontStyle: 'italic', textAlign: 'right'}}>{deliveryTime}</p>
        <p style={{ fontWeight: 400, textAlign: 'right'}}>Gesamtpreis (inkl. 19% Mwst.): {totalPrice}</p>
      </div>
      <div style={{ marginBottom: '60px', display: 'flex', justifyContent: 'flex-end' }}>
        <button style={{ width: '200px' }} className="global-button" onClick={handleCheckoutNew} disabled={!cart.length}>Zur Kasse</button>
      </div>   
    </div>
  );
}
