import React, { useState, useContext, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import theme from '../theme';
import { CartContext } from '../CartProvider';

function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { cart, showDialog, setShowDialog, latestAddedItem } = useContext(CartContext);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const onNavigateToCart = () => {
    setShowDialog(false);
    navigate('/cart');
  }

  useEffect(() => {
    if (showDialog) {
      const timer = setTimeout(() => {
        setShowDialog(false);
      }, 3000); // Hide the dialog after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [showDialog]);

  return (
    <header style={{ fontFamily: 'Roboto Slab, serif', color: theme.colors.textheader, backgroundColor: theme.colors.header }}>
    <div className="top-header" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src="/imgs/labelprint_grey_large.png" alt="Logo" className="center-logo" />
    </div>
    <div>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3 justify-content-center" light>
        <NavbarToggler style={{ border: 'none', boxShadow: 'none' }} onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse justify-content-center" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/shop">Shop</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/about">Über uns</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/contact">Kontakt</NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
      <div onClick={onNavigateToCart} className="nav-menu" >
        <img src="/imgs/cart.png" alt="Cart" style={{ height: '25px' }} />
          {cart.length > 0 && (
            <div style={{
              position: 'absolute',
              bottom: -5,
              right: -7,
              width: '17px',
              height: '17px',
              borderRadius: '50%',
              backgroundColor: '#A5B6AC',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '10px'
            }}>
              {cart.reduce((total, item) => total + item.quantity, 0)}
            </div>
          )}
          {showDialog && (
            <div style={{
              position: 'absolute',
              top: '-10',
              right: '0',
              width: 'auto',
              height: 'auto',
              backgroundColor: 'white',
              border: '1px #a6a39d',
              borderRadius: '3px',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left'
            }}>
              <p style={{ fontWeight: 400, textAlign: 'left' }}>Artikel zum Warenkorb hinzugefügt</p>
              {latestAddedItem && (
                <div className="nav-menu-container">
                  <img src={latestAddedItem.product.images[0]} alt={latestAddedItem.product.name} style={{ margin: '15px', marginRight: '25px', width: '120px', height: '120px' }} />
                  <div style={{
                    minWidth: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}>
                    <p>{latestAddedItem.product.name}</p>
                    <p>Anzahl: {latestAddedItem.quantity}</p>
                    <p style={{ fontWeight: 400 }}>Preis: {(parseFloat(latestAddedItem.totalPrice).toFixed(2))}€</p>
                  </div>
                </div>
              )}
              <button style={{ whiteSpace: 'nowrap' }} className="global-button" onClick={onNavigateToCart}>Zum Warenkorb</button>
            </div>
          )}
        </div>     
      </div>
    </header>
    );
  }

  export default NavMenu;
