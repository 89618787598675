import React, { createContext, useReducer, useEffect, useState } from 'react';

// Define the context
export const CartContext = createContext();

// Define the reducer
const cartReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_ITEM': {
        const itemIndex = state.findIndex(item => item.id === action.payload.id);
        
        if (itemIndex !== -1) {
          // Item already exists in cart
          const newState = [...state];
          newState[itemIndex].quantity += Number(action.payload.quantity);
          return newState;
        } 
        else 
        {
          // Item does not exist in cart
          return [...state, { ...action.payload, quantity: Number(action.payload.quantity) }];
        }
      }
      case 'UPDATE_ITEM': {
          const itemIndex = state.findIndex(item => item.id === action.payload.id);
          
          if (itemIndex !== -1) {
              // Item already exists in cart
              const newState = [...state];
              newState[itemIndex].quantity = Number(action.payload.quantity);
              return newState;
          }
          else
          {
            return [...state, { ...action.payload, quantity: Number(action.payload.quantity) }];
          }
        }
        case 'REMOVE_ITEM':
            return state.filter(item => item.id !== action.payload.id);
        case 'LOAD_CART':
            return action.payload;
        case 'CLEAR_CART':
          return [];
        default:
            return state;
    }
  };

// Define the provider
export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);
  const [showDialog, setShowDialog] = useState(false);
  const [latestAddedItem, setLatestAddedItem] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [mailAddress, setMailAddress] = useState(null);
  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);

  // Load cart from local storage when component mounts
  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      dispatch({ type: 'LOAD_CART', payload: JSON.parse(savedCart) });
    }
  }, []);

  // Save cart to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ 
            cart, 
            dispatch,  
            showDialog, 
            setShowDialog, 
            latestAddedItem, 
            setLatestAddedItem, 
            shippingAddress, 
            setShippingAddress, 
            billingAddress, 
            setBillingAddress,
            mailAddress,
            setMailAddress,
            differentBillingAddress,
            setDifferentBillingAddress }}>
      {children}
    </CartContext.Provider>
  );
};