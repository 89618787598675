import React, { useState, useEffect } from 'react';
import './Shop.css';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

export function Shop() {
  const navigate = useNavigate();
  const location = useLocation();
  const filterOptions = location.state;
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
      selectedFabrics: [],
      selectedColors: [],
      selectedSizes: []
    }
  );
  
  const { selectedFabrics, selectedColors, selectedSizes } = selectedOptions;

  // state initialization should be done using useState in functional components
  const [pageNumber, setPageNumber] = useState(1);
  const [fabrics, setFabrics] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [products, setProducts] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false); // Define hasNextPage
  const [randSortingSeed, setRandSortingSeed] = useState(Math.floor(Math.random() * 1000) + 1);

  useEffect(() => {
    // Fetch fabrics
    fetch('api/Shop/GetFabrics')
      .then(response => response.json())
      .then(data => setFabrics(data));

    // Fetch colors
    fetch('api/Shop/GetColors')
      .then(response => response.json())
      .then(data => setColors(data));

    // Fetch sizes
    fetch('api/Shop/GetSizes')
      .then(response => response.json())
      .then(data => setSizes(data));

    debouncedApiCall(true);
  }, []);

  useEffect(() => {
    if (location.state) {
      const { selectedFabrics, selectedColors, selectedSizes } = location.state;
      setSelectedOptions({ selectedFabrics, selectedColors, selectedSizes });
    }
  }, [location.state]);

  const handleButtonClick = (button) => {
    setSelectedButton(prevButton => prevButton === button ? null : button);
  };

  const handleOptionClick = (option, type) => {
    setSelectedOptions(prevOptions => {
      const newOptions = [...prevOptions[type]];
      const index = newOptions.indexOf(option);
      if (index > -1) {
        newOptions.splice(index, 1);
      } else {
        newOptions.push(option);
      }
      return { ...prevOptions, [type]: newOptions };
    });
  };

  useEffect(() => {
    debouncedApiCall(true);
  }, [selectedOptions]); 
  
  useEffect(() => {
    debouncedApiCall(false);
  }, [pageNumber]); 
  

  const handleLoadMore = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };

  const debouncedApiCall = _.debounce((shouldResetProducts) => {
    // Convert arrays to comma-separated strings
    const FabricIds = selectedFabrics.map(fabric => fabric.id).join(',');
    const Colors = selectedColors.map(color => color.filterColorName).join(',');
    const SizeIds = selectedSizes.map(size => size.id).join(',');
    const PageNumber = pageNumber;
    const PageSize = 12;
    const SortingIndex = randSortingSeed;

    const params = { PageNumber, PageSize, SortingIndex, FabricIds, Colors, SizeIds };

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== '') {
        searchParams.append(key, value);
      }
    });

    fetch(`api/Shop/GetProducts?${searchParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setProducts(prevProducts => shouldResetProducts ? [...data.products] : [...prevProducts, ...data.products]);
      setHasNextPage(data.hasNext);
    });
  }, 1000);

  const handleProductSelect = (product) => {
    navigate(`/product/${product}`, {
      state: {
        selectedFabrics: selectedFabrics,
        selectedColors: selectedColors,
        selectedSizes: selectedSizes
      }
    });
  }

  const renderOptions = () => {
    if (selectedButton === 'Stoff') {
      return (
      <div className="custom-container">
          {fabrics.map((fabric, index) => (
            <div key={fabric.id} onClick={() => handleOptionClick(fabric, 'selectedFabrics')} className="item" style={{ cursor: 'pointer' }}>
              <div style={{ marginBottom: '10px', padding: '5px', boxShadow: selectedFabrics.includes(fabric) ? '0 0 0 3px rgba(12, 179, 170, 0.5)' : 'none' }}>
                <img src={fabric.imagePath} alt={fabric} style={{ maxWidth: '150px', maxHeight: '150px'}}/>
              </div>
              <p>{fabric.fabricName}</p>
            </div>
          ))}
        </div>
      );
    }

    if (selectedButton === 'Farbe') {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '350px' }}>
          {colors.map((color) => (
            <div 
              key={color.id} 
              onClick={() => handleOptionClick(color, 'selectedColors')} 
              style={{ 
                margin: '5px',
                padding: '3px', 
                borderRadius: '50%', 
                boxShadow: selectedColors.includes(color) ? '0 0 0 3px rgba(12, 179, 170, 0.5)' : 'none',
                cursor: 'pointer'
              }}
            >
              <div 
                style={{ 
                  backgroundColor: color.filterColorName, 
                  borderRadius: '50%', 
                  height: '35px', 
                  width: '35px',
                }}
              />
            </div>
          ))}
        </div>
      );
    }

    if (selectedButton === 'Größe') {
      return sizes.map((size) => (
        <div key={size.id} style={{ justifyContent: 'center', cursor: 'pointer' }} onClick={() => handleOptionClick(size, 'selectedSizes')} >
          <div style={{ padding: '7px', margin: '20px', marginBottom: '10px', boxShadow: selectedSizes.includes(size) ? '0 0 0 3px rgba(12, 179, 170, 0.5)' : 'none' }}>
            <img src={size.imagePath} alt={size.dimensions} style={{ maxWidth: '100px', maxHeight: '100px' }}/>
          </div>
          <p style={{ textAlign: 'center' }}>{size.dimensions}</p>
        </div>
      ));
    }

    

    return null;
  };

    const buttons = ['Stoff', 'Farbe', 'Größe'];
    const buttonImages = ['fabrics', 'color', 'size'];

    return (
      <div>
        <Helmet>
          <title>Shop - ho.li design</title>
          <meta name="description" content="Willkommen bei ho.li design, eurem ersten Anlaufpunkt für stilvolle und gemütliche Poufs – für drinnen und draußen." />
          <meta name="keywords" content="ho.li design, holi design, Shop, Online Shop, Poufs, Pouffe, Pouf, Sitzsack, Beanbag, Hocker, Outdoor, Garten, Sitzkissen, boho, quadratisch, rechteckig, boucle, bouclé" />
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Shop</h1>
        <div className="button-container" style={{ marginBottom: '40px' }}>
          {buttons.map((button, index) => (
            <button key={button} className="global-button-white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px', marginBottom: '10px' }} onClick={() => handleButtonClick(button)}>
              <img src={`/imgs/${buttonImages[index]}.png`} alt={button} style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '10px' }}/>
              {button}
            </button>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {renderOptions()}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', fontSize: '16px', marginBottom: '30px' }}>
        {products.map((product) => (
          <div onClick={() => handleProductSelect(product.identifier)} key={product.identifier} style={{width: '200px', margin: '30px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}>
            <div style={{ width: '100%', height: '200px', display: 'flex', marginBottom: '10px' }}>
              <img src={product.image} style={{ flex: '1', objectFit: 'cover' }} alt="Product" />
            </div>
            <div style={{ wordWrap: 'break-word', minHeight: '46px' }}>{product.name}</div>
            {product.availability && 
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '16px' }}>
                    <span style={{ 
                      display: 'inline-block', 
                      height: '10px', 
                      width: '10px', 
                      borderRadius: '50%', 
                      backgroundColor: product.availability,
                      marginRight: '10px' 
                    }}></span>
                    <p style={{ margin: 0, fontWeight: 400 }}>{product.price}</p>
                  </div>
                }
          </div>
        ))}
        </div>
        {hasNextPage && 
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
            <button className="global-button" style={{ maxWidth: '250px' }} onClick={() => handleLoadMore()}>
              Mehr laden
            </button>
          </div>
        }
      </div>
    );
  
}