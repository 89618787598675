import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../CartProvider';
import './CheckoutSummary.css'; 

export function CheckoutSummary() {
  const navigate = useNavigate();
  const { cart, dispatch, shippingAddress, setShippingAddress, billingAddress, setBillingAddress, mailAddress, setMailAddress } = useContext(CartContext);
  const [productDetails, setProductDetails] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [deliveryCosts, setDeliveryCosts] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [isAgbAccepted, setIsAgbAccepted] = useState(false);

  useEffect(() => {
    loadProducts();
  }, []);

  const handleAgbAcceptedChange = (event) => {
    setIsAgbAccepted(event.target.checked);
  };

  const loadProducts = () => {
    // Prepare the cart data
    const cartData = cart.map(item => ({ key: item.id, value: item.quantity }));

    // Fetch product details
    fetch('api/Product/GetProductDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cartData),
    })
    .then(response => response.json())
    .then(data => 
      {
        setProductDetails(data.products);
        setTotalPrice(data.totalPrice);
        setDeliveryCosts(data.deliveryCost);
        setDeliveryTime(data.deliveryTime);
      });
  }

  const handleGoToPayment = async () => {
    // Prepare the cart data
    const cartData = cart.map(item => ({ key: item.id, value: item.quantity }));

    const request = {
      cart: cartData,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      mailAddress: mailAddress
    }
    // Fetch product details
    await fetch('api/Checkout/InitiateOrderPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
    .then(response => response.json()) // Parse the JSON response
    .then(data => {
      const pUrl = data.paymentUrl;
      window.location.href = pUrl;
    });
  }

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="checkout-summary" style={{ marginBottom: '60px' }}>
      <h1 style={{ marginBottom: '40px' }}>Zusammenfassung</h1>
      <div className="address-container" style={{ marginBottom: '40px' }}>
        <div className="address-section">
        <p><strong>Lieferadresse</strong></p>
          <div>
            <div>{mailAddress}</div>
            <br />
            <div>{shippingAddress.firstName} {shippingAddress.lastName}</div>
            {shippingAddress.companyName && <div>{shippingAddress.companyName}</div>}
            <div>{shippingAddress.addressLine1}</div>
            <div>{shippingAddress.zip} {shippingAddress.city}</div>
            <div>{shippingAddress.country}</div>
          </div>
        </div>
        {billingAddress && <div className="address-section" style={{ marginBottom: '40px' }} >
          <p><strong>Rechnungsadresse</strong></p>
          <div>
          <div>{billingAddress.firstName} {billingAddress.lastName}</div>
          {billingAddress.companyName && <div>{billingAddress.companyName}</div>}
          <div>{billingAddress.addressLine1}</div>
          <div>{billingAddress.zip} {shippingAddress.city}</div>
          <div>{billingAddress.country}</div>
        </div>
        </div>}
      </div>
      <div className="product-details-container">
          {productDetails && productDetails.map((productDetail) => (
              <div key={productDetail.identifier} className="product-detail-item">
                  <img src={productDetail.image} alt={productDetail.name} className="product-imagesc" />
                  <p>{productDetail.name}</p>
                  <p className="product-unit-price">Einzelpreis: {productDetail.unitPrice}</p>
                  <p>Anzahl: {productDetail.quantity}</p>
                  <p>Gesamt: {productDetail.totalPrice}</p>
              </div>
          ))}
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p style={{ fontStyle: 'italic', textAlign: 'right'}}>{deliveryCosts}</p>
        <p style={{ fontStyle: 'italic', textAlign: 'right'}}>{deliveryTime}</p>
        <p style={{ fontWeight: 400, textAlign: 'right'}}>Gesamtpreis (inkl. 19% Mwst.): {totalPrice}</p>
      </div>
      <div style={{ marginBottom: '30px', display: 'flex', alignItems: 'flex-start' }}>
            <input id="inputConsent" type="checkbox" checked={isAgbAccepted} onChange={handleAgbAcceptedChange} style={{ marginRight: '10px', marginTop: '5px' }} />
            <label htmlFor="inputConsent">
              Ich habe die <a href="/agb" target="_blank" rel="noopener noreferrer">AGBs</a> und <a href="/privacy" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> gelesen und akzeptiere sie.
            </label>
          </div>
      <div style={{ marginTop: '40px', marginBottom: '60px', display: 'flex', justifyContent: 'flex-end' }}>
        <button className="global-button" onClick={handleBack} style={{ margin: "10px", width: '200px' }}>Zurück</button>
        <button className="global-button" onClick={handleGoToPayment} disabled={!isAgbAccepted} style={{ margin: "10px", width: '200px' }}>Kaufen</button>
      </div>
    </div>
  );
}
