import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class About extends Component {
  static displayName = About.name;

  render() {
    return (
      <div style={{ marginBottom: '60px' }}>
        <Helmet>
          <title>Über uns - ho.li design</title>
          <meta name="description" content="Willkommen bei ho.li design, eurem ersten Anlaufpunkt für stilvolle und gemütliche Poufs – für drinnen und draußen." />
          <meta name="keywords" content="ho.li design, holi design" />
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Über uns</h1>
        <p>Herzlich willkommen bei ho.li design! Wir sind Laura und Andi. Für uns ist eine Wohnung oder ein Haus nicht nur ein Ort zum Zurückziehen und Wohlfühlen, sondern auch ein Platz, um mit Freunden und Familie Gemeinschaft zu genießen. Um unsere Leidenschaft für Wohnen und Einrichten vom Hobby zum Beruf zu machen, haben wir die ho.li design GmbH gegründet. <br/>
          <br/>
          Unser erstes Produkt, der ho.li Pouf, ist ein mit Styroporkügelchen (EPS-Perlen) gefüllter Sitzsack, der sowohl innen als auch außen verwendet werden kann. Er ist in verschiedenen Farben und Größen erhältlich. Wir arbeiten bereits auf Hochtouren an weiteren Produkten und hoffen, dass ihr viel Freude mit unseren Poufs habt. Solltet ihr Fragen zu uns oder unsreren Produkten haben, kontaktiert uns gerne.</p>
      </div>
    );
  }
}
